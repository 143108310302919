import { createSlice } from "@reduxjs/toolkit"
import { localCache } from "@/utils/cache"

const user_info = localCache.getItem('user_info')

const userSlice = createSlice({
  name: "user",
  initialState: {
    user_info: { ...user_info },
    use_detail: {
      deepl_total: 0,
      deepl_used: 0,
    }
  },
  reducers: {
    setUserState(state, { payload }) {
      return { ...state, ...payload };
    }
  }
})

export const { setUserState, setSecretKey } = userSlice.actions
export default userSlice.reducer