import { createSlice } from "@reduxjs/toolkit"
import { localCache } from "@/utils/cache"

const is_login = localStorage.getItem('token')
const user_info = localCache.getItem('user_info')

const mainSlice = createSlice({
  name: "main",
  initialState: {
    navigate: null,
    not_found_show: false,
    show_login: false,
    login_type: 'login',
    is_login: is_login && user_info ? true : false,
    message_box: {
      content: '',
      open: false
    },
    show_kefu: false
  },
  reducers: {
    setMainState(state, { payload }) {
      return { ...state, ...payload };
    },
    setNotFoundShow(state, { payload }) {
      state.not_found_show = payload
    },
    openLogin(state) {
      state.show_login = true
      state.login_type = 'login'
    },
    openRegister(state) {
      state.show_login = true
      state.login_type = 'register'
    },
    openForget(state) {
      state.show_login = true
      state.login_type = 'forget'
    },
    closeLogin(state) {
      state.show_login = false
    },
    setIsLogin(state, { payload }) {
      state.is_login = payload
    },
    setMessageBox(state, { payload }) {
      state.message_box = { ...payload }
    }
  }
})

export const { setNotFoundShow, openLogin, openRegister, openForget, closeLogin, setIsLogin, setMessageBox, setMainState } = mainSlice.actions
export default mainSlice.reducer