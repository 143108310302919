import React, { memo } from "react"
import FooterStyled from "./style"
import { useSelector } from "react-redux"

const Footer = memo(() => {
  const { not_found_show } = useSelector((state) => state.main)

  return (
    <FooterStyled $not_found_show={not_found_show}>
      <div>全球互译 - 企业级翻译API服务平台</div>
    </FooterStyled>
  )
})

export default Footer
