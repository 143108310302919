import styled, { keyframes, css } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const MessageStyled = styled.div`
  display: ${({ $open }) => $open ? 'flex' : 'none'};
  z-index: 999;
  position: fixed;
  top: 80px;
  width: 100%;
  justify-content: center;
  align-items: center;
  user-select: none;
  animation: ${({ $open }) =>
    $open
      ? css`${fadeIn} 0.3s forwards`
      : css`${fadeOut} 0.3s forwards`};

  &>div{
    height: 36px;
    border-radius: 5px;
    line-height: 36px;
    padding: 0 15px;
    background-color: #fff;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    i{
      vertical-align: middle;
      margin-right: 5px;
      font-size: 15px;
      color: ${({ $type }) => $type === 'success' ? '#52c41a' : ($type === 'fail' ? '#ff4d4f' : '#faad14')};
    }
  }

`
export default MessageStyled