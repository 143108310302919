import { configureStore } from "@reduxjs/toolkit"
import { headerReducer, mainReducer, userReducer, translateReducer } from "./modules"

const store = configureStore({
  reducer: {
    header: headerReducer,
    main: mainReducer,
    user: userReducer,
    translate: translateReducer
  },
})

export default store

