import store from '@/store'
import { setMessageBox, setMainState } from "@/store/modules/main"
import { setUserState } from "@/store/modules/user"

// const BASE_URL = 'https://www.qqhy.net/api';
const BASE_URL = '/api';
let navigateInstance = null

export function getNavigate(navigate) {
  navigateInstance = navigate;
}

async function sendRequest(param) {
  const { url, method = 'GET', body = null, headers = {}, responseType = 'json' } = param;
  const token = localStorage.getItem('token');
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  const options = {
    method,
    headers: {
      ...headers,
    }
  };
  if (body instanceof FormData) {
    // 如果 body 是 FormData，Content-Type 不需要设置
    options.body = body;
  } else if (body) {
    // 对于 JSON 或其他数据，设置 Content-Type
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(`${BASE_URL}${url}`, options);

    // 判断响应类型
    if (responseType === 'blob') {
      // 文件下载
      return response;
    } else {
      const data = await response.json();
      if (!response.ok) {
        const message = data?.error ? data?.error : (data?.message ? data?.message : '网络请求异常')
        store.dispatch(
          setMessageBox({
            content: message,
            open: true,
            type: 'fail'
          })
        );
      }
      // 处理token失效
      if (response.status === 401) {
        localStorage.clear();
        store.dispatch(
          setMainState({
            is_login: false,
            message_box: {
              content: "身份过期，重新登陆",
              open: true,
              type: "warn",
            },
          })
        );
        store.dispatch(setUserState({}));
        navigateInstance && navigateInstance('/home');
      }
      return data;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(
      setMessageBox({
        content: '网络请求异常',
        open: true,
        type: 'fail'
      })
    );
  }
}


export default sendRequest