import React, { Suspense, memo } from "react"
import MainStyled from "./style"
import Header from "@/components/header"
import Footer from "@/components/footer"
import MyRoutes from "@/router"
import Login from "@/components/login"
import Message from "@/components/message"
import Kefu from "@/components/kufu"

const Main = memo(() => {
  return (
    <MainStyled>
      <Suspense>
        <Header />
        <MyRoutes />
        <Footer />
        <Login />
        <Message />
        <Kefu />
      </Suspense>
    </MainStyled>
  )
})

export default Main
