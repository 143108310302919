import React, { useEffect } from "react"
import { useRoutes, Navigate, useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setMessageBox } from "@/store/modules/main"
import { getNavigate } from "@/utils/request"

const Home = React.lazy(() => import("@/pages/home"))
const Translation = React.lazy(() => import("@/pages/translation"))
const Price = React.lazy(() => import("@/pages/price"))
const Help = React.lazy(() => import("@/pages/help"))
const UserCenter = React.lazy(() => import("@/pages/user_center"))
const NotFound = React.lazy(() => import("@/pages/not_found"))

const MyRoutes = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { is_login } = useSelector(({ main }) => main)

  useEffect(() => {
    navigate && getNavigate(navigate)
  }, [navigate])

  useEffect(() => {
    if (!is_login && location.pathname === "/user_center") {
      dispatch(
        setMessageBox({
          content: "请先登录",
          type: "warn",
          open: true,
        })
      )
      navigate("/home")
    }
  }, [location.pathname])

  const routes = useRoutes([
    {
      path: "/",
      element: <Navigate to="/home" />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/translation",
      element: <Translation />,
    },
    {
      path: "/price",
      element: <Price />,
    },
    {
      path: "/help",
      element: <Help />,
    },
    {
      path: "/user_center",
      element: is_login && <UserCenter />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ])
  return routes
}

export default MyRoutes
