import React, { memo, useEffect } from "react"
import MessageStyle from "./style"
import { useSelector, useDispatch } from "react-redux"
import { setMessageBox } from "@/store/modules/main"

const Message = memo(() => {
  const dispatch = useDispatch()
  const { message_box } = useSelector((state) => state.main)
  const { content, open, type = 'success', duration = 3000 } = message_box
  useEffect(() => {
    open &&
      setTimeout(() => {
        dispatch(setMessageBox({
          ...message_box, open: false
        }))
      }, duration)
  }, [open])

  return (
    <MessageStyle $open={open} $type={type}>
      <div>
        {
          type === 'success' && <i className="iconfont">&#xe631;</i>
        }
        {
          type === 'fail' && <i className="iconfont">&#xe60b;</i>
        }
        {
          type === 'warn' && <i className="iconfont">&#xe66c;</i>
        }
        {content}
      </div>
    </MessageStyle>
  )
})

export default Message
