import { createSlice } from "@reduxjs/toolkit"

const init_state = {
  file: null,
  step: 1,
  document_id: '',
  document_key: '',
  loading_status: 'uploading',
  downloading: false
}

const userSlice = createSlice({
  name: "translate",
  initialState: init_state,
  reducers: {
    setTranslateState(state, { payload }) {
      return { ...state, ...payload };
    },
    ReSetTranslateState() {
      return init_state
    }
  }
})

export const { setTranslateState, ReSetTranslateState } = userSlice.actions
export default userSlice.reducer