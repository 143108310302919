import React, { memo, useEffect, useState, useRef } from "react"
import LoginStyled from "./style"
import login_bg from "@/assets/images/login_bg.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  openLogin,
  openRegister,
  openForget,
  closeLogin,
  setMainState,
  setMessageBox,
} from "@/store/modules/main"
import { setUserState } from "@/store/modules/user"
import { login, verificationCode, register, resetPassword } from "@/services"
import { localCache } from "@/utils/cache"

const Login = memo(() => {
  const dispatch = useDispatch()
  const { show_login, login_type } = useSelector((state) => state.main)
  const timer = useRef(null)
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [repeat_password, setRepeatPassword] = useState("")
  const [code, setCode] = useState("")
  const [second, setSecond] = useState(0)

  useEffect(() => {
    if (second <= 0) {
      clearInterval(timer.current)
    }
    if (second === 60) {
      timer.current = setInterval(() => {
        setSecond((prev_second) => prev_second - 1)
      }, 1000)
    }
  }, [second])

  return (
    <LoginStyled $show_login={show_login}>
      <div className="container">
        <div className="close" onClick={() => dispatch(closeLogin())}>
          <i className="iconfont">&#xe630;</i>
        </div>
        <div className="left">
          <div>
            <img src={login_bg} alt="" />
          </div>
          <div>
            「<span>全球互译</span>」
          </div>
          <div>提供专业的翻译API服务</div>
          <div>享受全网最低价的翻译服务</div>
        </div>
        <div className="right">
          <div>
            <div>
              {login_type == "forget" ? (
                "密码重置"
              ) : (
                <>
                  欢迎{login_type === "login" ? "登录" : "注册"}{" "}
                  <span>全球互译</span>
                </>
              )}
            </div>
            {login_type === "login" ? (
              <div className="login">
                <div>
                  <input
                    type="text"
                    placeholder="请输入手机号"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="请输入密码"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div onClick={() => dispatch(openForget())}>忘记密码？</div>
                <div>
                  <div onClick={() => onLogin()}>确认登录</div>
                  <div onClick={() => dispatch(openRegister())}>免费注册</div>
                </div>
              </div>
            ) : (
              <div className="register">
                <div>
                  <input
                    type="text"
                    placeholder="请输入手机号"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="请输入验证码"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  {second <= 0 ? (
                    <span onClick={() => getCode()}>获取验证码</span>
                  ) : (
                    <span className="second">{second}s重新获取</span>
                  )}
                </div>
                <div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="输入至少8位数字+字母密码"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="请确认密码"
                    value={repeat_password}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                  />
                </div>
                <div>
                  <div onClick={() => onRegisterOrForget()}>
                    {login_type === "register" ? "确认注册" : "确认重置"}
                  </div>
                  <div onClick={() => dispatch(openLogin())}>
                    已有账号，去登陆
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </LoginStyled>
  )

  async function getCode() {
    const res = await verificationCode(phone)
    if (res?.code === 1) {
      setSecond(60)
    }
  }

  async function onRegisterOrForget() {
    if (!validate()) return
    let res = null
    if (login_type === "register") {
      res = await register(phone, password, repeat_password, code)
    } else {
      res = await resetPassword(phone, password, repeat_password, code)
    }
    if (res?.code === 1) {
      dispatch(
        setMainState({
          is_login: false,
          show_login: true,
          login_type: "login",
          message_box: {
            content: res?.message ? res.message : "成功，请登录",
            open: true,
          },
        })
      )
    }
  }

  async function onLogin() {
    if (!validate()) return
    const res = await login(phone, password)
    if (res?.code === 1) {
      const token = res?.data?.token
      localStorage.setItem("token", token)
      localCache.setItem("user_info", res?.data)
      dispatch(
        setMainState({
          is_login: true,
          show_login: false,
          message_box: { content: "登录成功", open: true },
        })
      )
      dispatch(setUserState({ user_info: { ...res?.data } }))
    }
  }

  function validate() {
    let message = ""
    const phoneRegex = /^1[3-9]\d{9}$/
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    const phone_res = phoneRegex.test(phone)
    const password_res = passwordRegex.test(password)
    const repeat_password_res = passwordRegex.test(repeat_password)
    if (!password_res) message = "密码格式错误"
    if (!phone_res) message = "手机号格式错误"
    if (login_type === "register") {
      if (!code) message = "验证码为空"
      if (password !== repeat_password) message = "两次密码不一致"
      if (!repeat_password_res) message = "确认密码格式错误"
    }
    if (message) {
      dispatch(
        setMessageBox({
          content: message,
          open: true,
          type: "fail",
        })
      )
      return
    }
    message = ""
    return true
  }
})

export default Login
