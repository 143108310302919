import styled from 'styled-components';

const LoginStyled = styled.div`
  display: ${({ $show_login }) => $show_login ? 'flex' : 'none'};
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0,0,0,0.3);
  justify-content: center;
  align-items: center;
  user-select: none;

  .container{
    width: 600px;
    height: 400px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    position: relative;

    .close{
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      &:hover{
        color: red;
      }
    }

    .left{
      flex: 1;
      background-color: #f1faff;
      text-align: center;

      &>div:nth-child(1){
        margin-top: 30px;
        img{
          width: 250px;
        }
      }
      &>div:nth-child(2){
        margin-top: 30px;
        font-size: 30px;
        font-family: SHUHEI;

        span{
          color: #0065e5;
        }
      }
      &>div:nth-child(3){
        margin-top: 20px;
        color: #333;
      }
      &>div:nth-child(4){
        margin-top: 10px;
        color: #333;
      }


    }
    .right{
      flex: 1;

      &>div{
        width: 86%;
        margin: 0 auto;

        &>div:nth-child(1){
          margin-top: 30px;
          font-size: 24px;
          font-weight: 600;

          span{
            color: #0065e5;
          }
        }
        .login{
          margin-top: 60px;

          &>div:nth-child(1),
          &>div:nth-child(2){
            margin-top: 20px;
            height: 38px;
            line-height: 38px;
            border-radius: 3px;
            background-color: #f3f7fe;

            input{
              width: 90%;
              height: 30px;
              outline: none;
              border: none;
              background-color: #f3f7fe;
              padding: 0 10px;


              &::placeholder{
                color: #c0c4cb;
              }
            }
          }
          &>div:nth-child(3){
            margin-top: 10px;
            text-align: right;
            cursor: pointer;

            &:hover{
              color: #0065e5;
            }
          }
          &>div:nth-child(4){
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &>div:nth-child(1){
              height: 34px;
              padding: 0 15px;
              background-color: #0065e5;
              text-align: center;
              line-height: 34px;
              border-radius: 3px;
              color: #fff;
              cursor: pointer;
            }
            &>div:nth-child(2){
              height: 34px;
              padding: 0 15px;
              background-color: #e9edff;
              text-align: center;
              line-height: 34px;
              border-radius: 3px;
              color: #0065e5;
              cursor: pointer;
            }
          }
        }
        .register{
          &>div:nth-child(2){
            margin-top: 20px;
            height: 38px;
            line-height: 38px;
            border-radius: 3px;
            background-color: #f3f7fe;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .second{
              cursor: auto;
              color:rgb(117, 128, 157);
            }
            input{
              width: 50%;
              height: 30px;
              outline: none;
              border: none;
              background-color: #f3f7fe;
              padding: 0 10px;

              &::placeholder{
                color: #c0c4cb;
              }
            }
            span{
              padding: 0 10px;
              color: #3f66ff;
              cursor: pointer;
            }
          }
          &>div:nth-child(1),
          &>div:nth-child(3),
          &>div:nth-child(4){
            margin-top: 20px;
            height: 38px;
            line-height: 38px;
            border-radius: 3px;
            background-color: #f3f7fe;

            input{
              width: 90%;
              height: 30px;
              outline: none;
              border: none;
              background-color: #f3f7fe;
              padding: 0 10px;


              &::placeholder{
                color: #c0c4cb;
              }
            }
          }
          &>div:nth-child(5){
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &>div:nth-child(1){
              height: 34px;
              padding: 0 15px;
              background-color: #0065e5;
              text-align: center;
              line-height: 34px;
              border-radius: 3px;
              color: #fff;
              cursor: pointer;
            }
            &>div:nth-child(2){
              height: 34px;
              padding: 0 15px;
              background-color: #e9edff;
              text-align: center;
              line-height: 34px;
              border-radius: 3px;
              color: #0065e5;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  /* 在小屏幕上修改样式 */
  @media (max-width: 768px) {
    .container{
      width: 380px;
      
      .left{
        display: none;
      }
    }
  }
`
export default LoginStyled