import React, { memo } from "react"
import KefuStyled from "./style"
import { useSelector, useDispatch } from "react-redux"
import { setMainState } from "@/store/modules/main"
import vx from "@/assets/images/vx.png"

const Kufu = memo(() => {
  const dispatch = useDispatch()
  const { show_kefu } = useSelector((state) => state.main)


  return (
    <KefuStyled $open={show_kefu}>
      <div className="vx">
        <div className="close" onClick={() => dispatch(setMainState({ show_kefu: false }))}><i className="iconfont">&#xe639;</i></div>
        <div className="title">联系我们</div>
        <div className="vx_img">
          <img src={vx} alt="" />
        </div>
      </div>
    </KefuStyled>
  )
})

export default Kufu
