import React, { useEffect, memo } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import HeaderStyled from "./style"
import logo from "@/assets/images/logo.svg"
import { useDispatch, useSelector } from "react-redux"
import { setMenuIndex } from "@/store/modules/header"
import { openLogin, openRegister, setMainState } from "@/store/modules/main"
import { setUserState } from "@/store/modules/user"
import { logout } from "@/services"

const Header = memo(() => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { menu_index, change_bgc } = useSelector((state) => state.header)
  const { not_found_show, is_login } = useSelector(({ main }) => main)
  const { user_info } = useSelector(({ user }) => user)
  const { mobile, refresh } = user_info

  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        dispatch(setMenuIndex(0))
        break
      case "/translation":
        dispatch(setMenuIndex(1))
        break
      case "/price":
        dispatch(setMenuIndex(2))
        break
      case "/help":
        dispatch(setMenuIndex(3))
        break
      default:
        dispatch(setMenuIndex(99))
    }
  }, [dispatch, location])

  return (
    <HeaderStyled
      $menu_index={menu_index}
      $not_found_show={not_found_show}
      $change_bgc={change_bgc}
    >
      <div className="left">
        <img src={logo} alt="" onClick={() => navigate("/home")} />
        <div className="title">全球互译</div>
      </div>
      <div className="middle">
        <div onClick={() => navigate("/home")}>主页</div>
        <div onClick={() => navigate("/translation")}>翻译</div>
        <div onClick={() => navigate("/price")}>价格</div>
        <div onClick={() => navigate("/help")}>文档</div>
      </div>
      <div className="right">
        {is_login ? (
          <>
            {/* <div>控制台</div> */}
            <div className="menu-container">
              <div className="menu-button">
                {mobile} <i className="iconfont">&#xe621;</i>
              </div>
              <div className="dropdown-menu">
                <div
                  className="dropdown-item"
                  onClick={() => navigate("/user_center")}
                >
                  用户中心
                </div>
                <div onClick={() => onLogout()} className="dropdown-item">
                  退出登录
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="login" onClick={() => dispatch(openLogin())}>
              登录
            </div>
            <div className="register" onClick={() => dispatch(openRegister())}>
              注册
            </div>
          </>
        )}
      </div>
    </HeaderStyled>
  )

  async function onLogout() {
    const res = await logout(refresh)
    if (res?.code === 1) {
      navigate("/home")
      localStorage.clear()
      dispatch(
        setMainState({
          is_login: false,
          message_box: {
            content: "退出成功",
            open: true,
          },
        })
      )
      dispatch(setUserState({ user_info: {}, use_detail: {} }))
    }
  }
})

export default Header
