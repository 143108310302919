import sendRequest from '@/utils/request'

// 登录接口
export async function login(mobile, password) {
  return sendRequest({
    url: '/users/login',
    method: 'post',
    body: {
      username: mobile,
      mobile,
      password
    }
  })
}

// 获取验证码接口
export async function verificationCode(mobile) {
  return sendRequest({
    method: 'post',
    url: '/users/sendsms',
    body: {
      mobile
    }
  })
}

// 注册接口
export async function register(mobile, password, password_confirmation, code) {
  return sendRequest({
    url: '/users/register',
    method: 'post',
    body: {
      mobile,
      password,
      password_confirmation,
      code
    }
  })
}

// 登出接口
export async function logout(refresh) {
  return sendRequest({
    url: '/users/logout ',
    method: 'post',
    body: {
      refresh
    }
  })
}

// 获取当前用户信息
export async function getUser(id) {
  return sendRequest({
    url: `/users/${id}`
  })
}

// 获取当前用户使用详情
export async function getUseDeatil() {
  return sendRequest({
    url: `/translate/use_detail`
  })
}

// 重置密钥
export async function resetKey() {
  return sendRequest({
    url: '/users/reset_key',
    method: 'put'
  })
}

// 重置密码
export async function resetPassword(mobile, password, password_confirmation, code) {
  return sendRequest({
    url: '/users/reset_password',
    method: 'put',
    body: {
      mobile,
      password,
      password_confirmation,
      code
    }
  })
}

// 文本翻译deepl
export async function translateDeepl(source_lang, target_lang, text) {
  return sendRequest({
    url: '/translate/text_web',
    method: 'post',
    body: {
      source_lang,
      target_lang,
      text,
    }
  })
}

// 文档上传
export async function documentUpload(formData) {
  return sendRequest({
    url: '/translate/document',
    method: 'post',
    body: formData,  // 直接传入 formData 对象
  })
}

//文档翻译状态检查
export async function documentCheck(document_id, document_key, secret_key) {
  return sendRequest({
    url: `/translate/document?document_id=${document_id}&document_key=${document_key}&secret_key=${secret_key}`,
    method: 'get'
  })
}

//文档下载
export async function documentDownload(document_id, document_key, secret_key) {
  return sendRequest({
    url: '/translate/document/result',
    method: 'post',
    body: {
      document_id,
      document_key,
      secret_key
    },
    responseType: 'blob'
  })
}