import styled from 'styled-components';
import kefu2 from "@/assets/images/kefu2.png"

const KefuStyled = styled.div`
  display: ${({ $open }) => $open ? 'flex' : 'none'};
  z-index: 999;
  position: fixed;
  top: 25%;
  width: 100%;
  justify-content: center;
  align-items: center;
  user-select: none;

  .vx{
    height: 480px;
    width: 400px;
    border-radius: 5px;
    line-height: 36px;
    padding: 0 15px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    background: url(${kefu2});
    background-size: 200px 200px;
    background-position: right top;
    background-repeat: no-repeat;
    background-color: #f2f8fd;
    position: relative;

    .close{
      position: absolute;
      right: 0;
      padding: 0 10px;
      cursor: pointer;
      &:hover{
        color: red;
      }
    }
    .title{
      font-size: 24px;
      text-align: center;
      margin-top: 30px;
    }
    .vx_img{
      margin-top: 90px;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        width: 230px;
        height: 230px;
      }
    }
  }

`
export default KefuStyled