import styled from 'styled-components';

const HeaderStyled = styled.div`
  box-sizing: border-box;
  z-index: 999;
  position: fixed;
  top: 0;
  user-select: none;
  height: 60px;
  width: 100vw;
  border-bottom: 1px solid #e4e4e4;
  display: ${({ $not_found_show }) => $not_found_show ? "none" : "flex"};
  @supports (backdrop-filter: blur(8px)) {
    backdrop-filter: blur(8px);
    background-color: ${({ $change_bgc }) => $change_bgc ? "transparent" : "#ffffff"};
  }
  @supports not (backdrop-filter: blur(8px)) {
    background-color: ${({ $change_bgc }) => $change_bgc ? "rgba(255,255,255,0.8)" : "#ffffff"};
  }
  align-items: center;
  white-space: nowrap;
  font-size: 16px;

  .left{
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 44px;
      height: 44px;
      cursor: pointer;
    }
    .title{
      font-family: SHUHEI;
      margin-left: 10px;
      font-size: 24px;
      font-weight: 700;
      color: #21262e;
    }
  }
 
  .middle{
      flex: 1.5;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #4e5969;

      div{
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        border-radius: 3px;
        cursor: pointer;
        &:hover{
          color: #0065e5;
        }
        &:nth-child(${({ $menu_index }) => $menu_index + 1}){
          background-color: #dee9fd;
          color: #0065e5;
        }
      }
    }

  .right{
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    .login{
      color: #4e5969;
      font-size: 16px;
      cursor: pointer;
      &:hover{
          color: #0065e5;
        }
    }
    .register{
      margin-left: 15px;
      line-height: 40px;
      padding: 0 15px;
      height: 40px;
      border-radius: 5px;
      background: #0065e5;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
      &:hover{
        background: rgba(0, 101, 229, 0.7)
      }
    }

    // 下拉菜单 start
    .menu-container {
      position: relative;
      display: inline-block;
      margin-left: 20px;
    }

    .menu-button {
      cursor: pointer;

      i{
        margin-left: 2px;
        font-size: 14px;
      }
    }

    .dropdown-menu {
      display: none;
      position: absolute;
      right: 0;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    .menu-container:hover .dropdown-menu {
      display: block;
    }

    .dropdown-item {
      padding: 8px 16px;
      text-decoration: none;
      display: block;
      color: black;
      cursor: pointer;
    }

    .dropdown-item:hover {
      background-color: #f5f5f5;
    }
    // 下拉菜单 end
  }

  /* 在小屏幕上修改样式 */
  @media (max-width: 768px) {
    @supports (backdrop-filter: blur(8px)) {
      backdrop-filter: blur(8px);
      background-color: ${({ $change_bgc }) => $change_bgc ? "transparent" : "#ffffff"};
    }
    @supports not (backdrop-filter: blur(8px)) {
      background-color: ${({ $change_bgc }) => $change_bgc ? "rgba(255,255,255,0.8)" : "#ffffff"};
    }

    .left{

      .title{
        display: none;
      }
    }
    .right{

      .register{
        margin-left: 10px;
        line-height: 30px;
        padding: 0 10px;
        height: 30px;
      }
    }
  }

`
export default HeaderStyled