import styled from 'styled-components';

const FooterStyled = styled.div`
  width: 100%;
  height: 60px;
  background-color: #031a4e;
  display: ${({ $not_found_show }) => $not_found_show ? "none" : "flex"};
  justify-content: center;
  align-items: center;
  color: #fff;
`
export default FooterStyled