import { createSlice } from "@reduxjs/toolkit"

const headerSlice = createSlice({
  name: "header",
  initialState: {
    menu_index: 0,
    change_bgc: false
  },
  reducers: {
    setMenuIndex(state, { payload }) {
      state.menu_index = payload
    },
    setChangeBgc(state, { payload }) {
      state.change_bgc = payload
    }
  }
})

export const { setMenuIndex, setChangeBgc } = headerSlice.actions
export default headerSlice.reducer